<template>
      <div class="center"><app-container :container='containers.center'/></div>
</template>

<script>
      import Container from "../Container.vue";
      import json_data from "./assets/Lux-Voyages-Data.json";

      export default {
            data(){
                  return {containers: json_data.containers}
            },
            components: {
                  appContainer: Container
            }
      }
</script>
