import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import * as VueGoogleMaps from "vue2-google-maps"

Vue.config.productionTip = false

import Vue2TouchEvents from 'vue2-touch-events';
import VueLazyLoad from 'vue-lazyload';
import InlineEdit from "./components/helpers/Inline-Edit-Helper"

Vue.use(Vue2TouchEvents)
Vue.use(VueLazyLoad)

Vue.use(VueRouter)

import LuxVoyagesHome from './components/pages/Lux-Voyages-Home.vue';
import LuxVoyagesAbout from './components/pages/Lux-Voyages-About.vue';
import LuxVoyagesStoriesNah from './components/pages/Lux-Voyages-Stories-Nah.vue';
import LuxVoyagesStoriesFern from './components/pages/Lux-Voyages-Stories-Fern.vue';
import LuxVoyagesContact from './components/pages/Lux-Voyages-Contact.vue';
import LuxVoyagesData from './components/pages/Lux-Voyages-Data.vue';
import LuxVoyagesImpressum from './components/pages/Lux-Voyages-Impressum.vue';


const scrollBehavior = function (to, from, savedPosition) {
  if(savedPosition){
    return savedPosition;
  }else{
    if (to.hash && from) {
      return {
        selector: to.hash
      }
    }
  }
}

const router = new VueRouter({
mode: 'history',
scrollBehavior,
routes: [
    {path:'/', component: LuxVoyagesHome},
{path:'/.html', component: LuxVoyagesHome},
{path:'/about', component: LuxVoyagesAbout},
{path:'/about.html', component: LuxVoyagesAbout},
{path:'/stories/index', component: LuxVoyagesStoriesNah},
{path:'/stories/index.html', component: LuxVoyagesStoriesNah},
{path:'/stories/fern', component: LuxVoyagesStoriesFern},
{path:'/stories/fern.html', component: LuxVoyagesStoriesFern},
{path:'/contact', component: LuxVoyagesContact},
{path:'/contact.html', component: LuxVoyagesContact},
{path:'/data', component: LuxVoyagesData},
{path:'/data.html', component: LuxVoyagesData},
{path:'/impressum', component: LuxVoyagesImpressum},
{path:'/impressum.html', component: LuxVoyagesImpressum},
{path:'*', component: LuxVoyagesHome},

]

})

Vue.use(VueGoogleMaps, {
  load: {
        key:"AIzaSyAEJ9f6QRhUzov-Xyvqc4Q7i29EGU3rJJU",
        libraries: "places"
  }
});

Vue.component('inline-edit', InlineEdit)

new Vue({
  render: h => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
